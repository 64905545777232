import { Country } from "../../types/common/country";

export const countries: Country[] = [
  {
    name: "France",
    flag: "🇫🇷",
    isoAlpha2Code: "FR",
    dial_code: "+33",
    isoAlpha3Code: "FRA",
    country: {
      en: "France",
      fr: "France",
    },
    nationality: {
      en: "French",
      fr: "Française",
    },
  },
  {
    name: "United Kingdom",
    flag: "🇬🇧",
    isoAlpha2Code: "GB",
    dial_code: "+44",
    isoAlpha3Code: "GBR",
    country: {
      en: "United Kingdom",
      fr: "Royaume-Uni",
    },
    nationality: {
      en: "British",
      fr: "Britannique",
    },
  },
  {
    name: "United States",
    flag: "🇺🇸",
    isoAlpha2Code: "US",
    dial_code: "+1",
    isoAlpha3Code: "USA",
    country: {
      en: "United States of America",
      fr: "États-Unis",
    },
    nationality: {
      en: "American",
      fr: "Américaine",
    },
  },
  {
    name: "Afghanistan",
    flag: "🇦🇫",
    isoAlpha2Code: "AF",
    dial_code: "+93",
    isoAlpha3Code: "AFG",
    country: {
      en: "Afghanistan",
      fr: "Afghanistan",
    },
    nationality: {
      en: "Afghan",
      fr: "Afghane",
    },
  },
  {
    name: "Åland Islands",
    flag: "🇦🇽",
    isoAlpha2Code: "AX",
    dial_code: "+358",
    isoAlpha3Code: "ALA",
    country: {
      en: "Aland Islands",
      fr: "Iles Åland",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Albania",
    flag: "🇦🇱",
    isoAlpha2Code: "AL",
    dial_code: "+355",
    isoAlpha3Code: "ALB",
    country: {
      en: "Albania",
      fr: "Albanie",
    },
    nationality: {
      en: "Albanian",
      fr: "Albanaise",
    },
  },
  {
    name: "Algeria",
    flag: "🇩🇿",
    isoAlpha2Code: "DZ",
    dial_code: "+213",
    isoAlpha3Code: "DZA",
    country: {
      en: "Algeria",
      fr: "Algérie",
    },
    nationality: {
      en: "Algerian",
      fr: "Algérienne",
    },
  },
  {
    name: "American Samoa",
    flag: "🇦🇸",
    isoAlpha2Code: "AS",
    dial_code: "+1684",
    isoAlpha3Code: "ASM",
    country: {
      en: "American Samoa",
      fr: "Samoa américaines",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Andorra",
    flag: "🇦🇩",
    isoAlpha2Code: "AD",
    dial_code: "+376",
    isoAlpha3Code: "AND",
    country: {
      en: "Andorra",
      fr: "Andorre",
    },
    nationality: {
      en: "Andorran",
      fr: "Andorrane",
    },
  },
  {
    name: "Angola",
    flag: "🇦🇴",
    isoAlpha2Code: "AO",
    dial_code: "+244",
    isoAlpha3Code: "AGO",
    country: {
      en: "Angola",
      fr: "Angola",
    },
    nationality: {
      en: "Angolan",
      fr: "Angolaise",
    },
  },
  {
    name: "Anguilla",
    flag: "🇦🇮",
    isoAlpha2Code: "AI",
    dial_code: "+1264",
    isoAlpha3Code: "AIA",
    country: {
      en: "Anguilla",
      fr: "Anguilla",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Antarctica",
    flag: "🇦🇶",
    isoAlpha2Code: "AQ",
    dial_code: "+672",
    isoAlpha3Code: "ATA",
    country: {
      en: "Antarctica",
      fr: "Antarctique",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Antigua and Barbuda",
    flag: "🇦🇬",
    isoAlpha2Code: "AG",
    dial_code: "+1268",
    isoAlpha3Code: "ATG",
    country: {
      en: "Antigua and Barbuda",
      fr: "Antigua-et-Barbuda",
    },
    nationality: {
      en: "Antiguan or Barbudan",
      fr: "Antiguaise et barbudienne",
    },
  },
  {
    name: "Argentina",
    flag: "🇦🇷",
    isoAlpha2Code: "AR",
    dial_code: "+54",
    isoAlpha3Code: "ARG",
    country: {
      en: "Argentina",
      fr: "Argentine",
    },
    nationality: {
      en: "Argentine",
      fr: "Argentine",
    },
  },
  {
    name: "Armenia",
    flag: "🇦🇲",
    isoAlpha2Code: "AM",
    dial_code: "+374",
    isoAlpha3Code: "ARM",
    country: {
      en: "Armenia",
      fr: "Arménie",
    },
    nationality: {
      en: "Armenian",
      fr: "Armenienne",
    },
  },
  {
    name: "Aruba",
    flag: "🇦🇼",
    isoAlpha2Code: "AW",
    dial_code: "+297",
    isoAlpha3Code: "ABW",
    country: {
      en: "Aruba",
      fr: "Aruba",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Australia",
    flag: "🇦🇺",
    isoAlpha2Code: "AU",
    dial_code: "+61",
    isoAlpha3Code: "AUS",
    country: {
      en: "Australia",
      fr: "Australie",
    },
    nationality: {
      en: "Australian",
      fr: "Australienne",
    },
  },
  {
    name: "Austria",
    flag: "🇦🇹",
    isoAlpha2Code: "AT",
    dial_code: "+43",
    isoAlpha3Code: "AUT",
    country: {
      en: "Austria",
      fr: "Autriche",
    },
    nationality: {
      en: "Austrian",
      fr: "Autrichienne",
    },
  },
  {
    name: "Azerbaijan",
    flag: "🇦🇿",
    isoAlpha2Code: "AZ",
    dial_code: "+994",
    isoAlpha3Code: "AZE",
    country: {
      en: "Azerbaijan",
      fr: "Azerbaïdjan",
    },
    nationality: {
      en: "Azerbaijani",
      fr: "Azerbaïdjanaise",
    },
  },
  {
    name: "Bahamas",
    flag: "🇧🇸",
    isoAlpha2Code: "BS",
    dial_code: "+1242",
    isoAlpha3Code: "BHS",
    country: {
      en: "Bahamas",
      fr: "Bahamas",
    },
    nationality: {
      en: "Bahamian",
      fr: "Bahamienne",
    },
  },
  {
    name: "Bahrain",
    flag: "🇧🇭",
    isoAlpha2Code: "BH",
    dial_code: "+973",
    isoAlpha3Code: "BHR",
    country: {
      en: "Bahrain",
      fr: "Bahreïn",
    },
    nationality: {
      en: "Bahraini",
      fr: "Bahreinienne",
    },
  },
  {
    name: "Bangladesh",
    flag: "🇧🇩",
    isoAlpha2Code: "BD",
    dial_code: "+880",
    isoAlpha3Code: "BGD",
    country: {
      en: "Bangladesh",
      fr: "Bangladesh",
    },
    nationality: {
      en: "Bangladeshi",
      fr: "Bangladaise",
    },
  },
  {
    name: "Barbados",
    flag: "🇧🇧",
    isoAlpha2Code: "BB",
    dial_code: "+1246",
    isoAlpha3Code: "BRB",
    country: {
      en: "Barbados",
      fr: "Barbade",
    },
    nationality: {
      en: "Barbadian",
      fr: "Barbadienne",
    },
  },
  {
    name: "Belarus",
    flag: "🇧🇾",
    isoAlpha2Code: "BY",
    dial_code: "+375",
    isoAlpha3Code: "BLR",
    country: {
      en: "Belarus",
      fr: "Bélarus",
    },
    nationality: {
      en: "Belarusian",
      fr: "Bielorusse",
    },
  },
  {
    name: "Belgium",
    flag: "🇧🇪",
    isoAlpha2Code: "BE",
    dial_code: "+32",
    isoAlpha3Code: "BEL",
    country: {
      en: "Belgium",
      fr: "Belgique",
    },
    nationality: {
      en: "Belgian",
      fr: "Belge",
    },
  },
  {
    name: "Belize",
    flag: "🇧🇿",
    isoAlpha2Code: "BZ",
    dial_code: "+501",
    isoAlpha3Code: "BLZ",
    country: {
      en: "Belize",
      fr: "Belize",
    },
    nationality: {
      en: "Belizean",
      fr: "Belizienne",
    },
  },
  {
    name: "Benin",
    flag: "🇧🇯",
    isoAlpha2Code: "BJ",
    dial_code: "+229",
    isoAlpha3Code: "BEN",
    country: {
      en: "Benin",
      fr: "Bénin",
    },
    nationality: {
      en: "Beninese",
      fr: "Beninoise",
    },
  },
  {
    name: "Bermuda",
    flag: "🇧🇲",
    isoAlpha2Code: "BM",
    dial_code: "+1441",
    isoAlpha3Code: "BMU",
    country: {
      en: "Bermuda",
      fr: "Bermudes",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Bhutan",
    flag: "🇧🇹",
    isoAlpha2Code: "BT",
    dial_code: "+975",
    isoAlpha3Code: "BTN",
    country: {
      en: "Bhutan",
      fr: "Bhoutan",
    },
    nationality: {
      en: "Bhutanese",
      fr: "Bhoutanaise",
    },
  },
  {
    name: "Bolivia (Plurinational State of)",
    flag: "🇧🇴",
    isoAlpha2Code: "BO",
    dial_code: "+591",
    isoAlpha3Code: "BOL",
    country: {
      en: "Bolivia (Plurinational State of)",
      fr: "Bolivie",
    },
    nationality: {
      en: "Bolivian",
      fr: "Bolivienne",
    },
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    flag: "🇧🇶",
    isoAlpha2Code: "BQ",
    dial_code: "+599",
    isoAlpha3Code: "BES",
    country: {
      en: "Bonaire, Sint Eustatius and Saba",
      fr: "Bonaire, Saint-Eustache et Saba",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Bosnia and Herzegovina",
    flag: "🇧🇦",
    isoAlpha2Code: "BA",
    dial_code: "+387",
    isoAlpha3Code: "BIH",
    country: {
      en: "Bosnia and Herzegovina",
      fr: "Bosnie-Herzégovine",
    },
    nationality: {
      en: "Bosnian",
      fr: "Bosnienne",
    },
  },
  {
    name: "Botswana",
    flag: "🇧🇼",
    isoAlpha2Code: "BW",
    dial_code: "+267",
    isoAlpha3Code: "BWA",
    country: {
      en: "Botswana",
      fr: "Botswana",
    },
    nationality: {
      en: "Botswanan",
      fr: "Botswanaise",
    },
  },
  {
    name: "Bouvet Island",
    flag: "🇧🇻",
    isoAlpha2Code: "BV",
    dial_code: "+47",
    isoAlpha3Code: "BVT",
    country: {
      en: "Bouvet Island",
      fr: "Ile Bouvet",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Brazil",
    flag: "🇧🇷",
    isoAlpha2Code: "BR",
    dial_code: "+55",
    isoAlpha3Code: "BRA",
    country: {
      en: "Brazil",
      fr: "Brésil",
    },
    nationality: {
      en: "Brazilian",
      fr: "Bresilienne",
    },
  },
  {
    name: "British Indian Ocean Territory",
    flag: "🇮🇴",
    isoAlpha2Code: "IO",
    dial_code: "+246",
    isoAlpha3Code: "IOT",
    country: {
      en: "British Indian Ocean Territory",
      fr: "Territoire britannique de l'océan Indien",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Brunei Darussalam",
    flag: "🇧🇳",
    isoAlpha2Code: "BN",
    dial_code: "+673",
    isoAlpha3Code: "BRN",
    country: {
      en: "Brunei Darussalam",
      fr: "Brunéi Darussalam",
    },
    nationality: {
      en: "Bruneian",
      fr: "Bruneienne",
    },
  },
  {
    name: "Bulgaria",
    flag: "🇧🇬",
    isoAlpha2Code: "BG",
    dial_code: "+359",
    isoAlpha3Code: "BGR",
    country: {
      en: "Bulgaria",
      fr: "Bulgarie",
    },
    nationality: {
      en: "Bulgarian",
      fr: "Bulgare",
    },
  },
  {
    name: "Burkina Faso",
    flag: "🇧🇫",
    isoAlpha2Code: "BF",
    dial_code: "+226",
    isoAlpha3Code: "BFA",
    country: {
      en: "Burkina Faso",
      fr: "Burkina Faso",
    },
    nationality: {
      en: "Burkinabé",
      fr: "Burkinabe",
    },
  },
  {
    name: "Burundi",
    flag: "🇧🇮",
    isoAlpha2Code: "BI",
    dial_code: "+257",
    isoAlpha3Code: "BDI",
    country: {
      en: "Burundi",
      fr: "Burundi",
    },
    nationality: {
      en: "Burundian",
      fr: "Burundaise",
    },
  },
  {
    name: "Cabo Verde",
    flag: "🇨🇻",
    isoAlpha2Code: "CV",
    dial_code: "+238",
    isoAlpha3Code: "CPV",
    country: {
      en: "Cabo Verde",
      fr: "Cap-Vert",
    },
    nationality: {
      en: "Cape Verdean",
      fr: "Cap-verdienne",
    },
  },
  {
    name: "Cambodia",
    flag: "🇰🇭",
    isoAlpha2Code: "KH",
    dial_code: "+855",
    isoAlpha3Code: "KHM",
    country: {
      en: "Cambodia",
      fr: "Cambodge",
    },
    nationality: {
      en: "Cambodian",
      fr: "Cambodgienne",
    },
  },
  {
    name: "Cameroon",
    flag: "🇨🇲",
    isoAlpha2Code: "CM",
    dial_code: "+237",
    isoAlpha3Code: "CMR",
    country: {
      en: "Cameroon",
      fr: "Cameroun",
    },
    nationality: {
      en: "Cameroonian",
      fr: "Camerounaise",
    },
  },
  {
    name: "Canada",
    flag: "🇨🇦",
    isoAlpha2Code: "CA",
    dial_code: "+1",
    isoAlpha3Code: "CAN",
    country: {
      en: "Canada",
      fr: "Canada",
    },
    nationality: {
      en: "Canadian",
      fr: "Canadienne",
    },
  },
  {
    name: "Cayman Islands",
    flag: "🇰🇾",
    isoAlpha2Code: "KY",
    dial_code: "+1345",
    isoAlpha3Code: "CYM",
    country: {
      en: "Cayman Islands",
      fr: "Îles Caïmans",
    },
    nationality: {
      en: "Caymanian",
      fr: "Caïmanienne",
    },
  },
  {
    name: "Central African Republic",
    flag: "🇨🇫",
    isoAlpha2Code: "CF",
    dial_code: "+236",
    isoAlpha3Code: "CAF",
    country: {
      en: "Central African Republic",
      fr: "République centrafricaine",
    },
    nationality: {
      en: "Central African",
      fr: "Centrafricaine",
    },
  },
  {
    name: "Chad",
    flag: "🇹🇩",
    isoAlpha2Code: "TD",
    dial_code: "+235",
    isoAlpha3Code: "TCD",
    country: {
      en: "Chad",
      fr: "Tchad",
    },
    nationality: {
      en: "Chadian",
      fr: "Tchadienne",
    },
  },
  {
    name: "Chile",
    flag: "🇨🇱",
    isoAlpha2Code: "CL",
    dial_code: "+56",
    isoAlpha3Code: "CHL",
    country: {
      en: "Chile",
      fr: "Chili",
    },
    nationality: {
      en: "Chilean",
      fr: "Chilienne",
    },
  },
  {
    name: "China",
    flag: "🇨🇳",
    isoAlpha2Code: "CN",
    dial_code: "+86",
    isoAlpha3Code: "CHN",
    country: {
      en: "China",
      fr: "Chine",
    },
    nationality: {
      en: "Chinese",
      fr: "Chinoise",
    },
  },
  {
    name: "Christmas Island",
    flag: "🇨🇽",
    isoAlpha2Code: "CX",
    dial_code: "+61",
    isoAlpha3Code: "CXR",
    country: {
      en: "Christmas Island",
      fr: "Île Christmas",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Cocos (Keeling) Islands",
    flag: "🇨🇨",
    isoAlpha2Code: "CC",
    dial_code: "+61",
    isoAlpha3Code: "CCK",
    country: {
      en: "Cocos (Keeling) Islands",
      fr: "Îles Cocos (Keeling)",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Colombia",
    flag: "🇨🇴",
    isoAlpha2Code: "CO",
    dial_code: "+57",
    isoAlpha3Code: "COL",
    country: {
      en: "Colombia",
      fr: "Colombie",
    },
    nationality: {
      en: "Colombian",
      fr: "Colombienne",
    },
  },
  {
    name: "Comoros",
    flag: "🇰🇲",
    isoAlpha2Code: "KM",
    dial_code: "+269",
    isoAlpha3Code: "COM",
    country: {
      en: "Comoros",
      fr: "Comores",
    },
    nationality: {
      en: "Comoran",
      fr: "Comorienne",
    },
  },
  {
    name: "Congo",
    flag: "🇨🇬",
    isoAlpha2Code: "CG",
    dial_code: "+242",
    isoAlpha3Code: "COG",
    country: {
      en: "Congo",
      fr: "Congo",
    },
    nationality: {
      en: "Congolese",
      fr: "Congolaise",
    },
  },
  {
    name: "Congo (Democratic Republic of the)",
    flag: "🇨🇩",
    isoAlpha2Code: "CD",
    dial_code: "+243",
    isoAlpha3Code: "COD",
    country: {
      en: "Congo (Democratic Republic of the)",
      fr: "Congo (République démocratique du)",
    },
    nationality: {
      en: "Congolese",
      fr: "Congolaise",
    },
  },
  {
    name: "Cook Islands",
    flag: "🇨🇰",
    isoAlpha2Code: "CK",
    dial_code: "+682",
    isoAlpha3Code: "COK",
    country: {
      en: "Cook Islands",
      fr: "Îles Cook",
    },
    nationality: {
      en: "Cook Islander",
      fr: "Cookienne",
    },
  },
  {
    name: "Costa Rica",
    flag: "🇨🇷",
    isoAlpha2Code: "CR",
    dial_code: "+506",
    isoAlpha3Code: "CRI",
    country: {
      en: "Costa Rica",
      fr: "Costa Rica",
    },
    nationality: {
      en: "Costa Rican",
      fr: "Costaricienne",
    },
  },
  {
    name: "Croatia",
    flag: "🇭🇷",
    isoAlpha2Code: "HR",
    dial_code: "+385",
    isoAlpha3Code: "HRV",
    country: {
      en: "Croatia",
      fr: "Croatie",
    },
    nationality: {
      en: "Croatian",
      fr: "Croate",
    },
  },
  {
    name: "Cuba",
    flag: "🇨🇺",
    isoAlpha2Code: "CU",
    dial_code: "+53",
    isoAlpha3Code: "CUB",
    country: {
      en: "Cuba",
      fr: "Cuba",
    },
    nationality: {
      en: "Cuban",
      fr: "Cubaine",
    },
  },
  {
    name: "Curaçao",
    flag: "🇨🇼",
    isoAlpha2Code: "CW",
    dial_code: "+599",
    isoAlpha3Code: "CUW",
    country: {
      en: "Curaçao",
      fr: "Curaçao",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Cyprus",
    flag: "🇨🇾",
    isoAlpha2Code: "CY",
    dial_code: "+357",
    isoAlpha3Code: "CYP",
    country: {
      en: "Cyprus",
      fr: "Chypre",
    },
    nationality: {
      en: "Cypriot",
      fr: "Chypriote",
    },
  },
  {
    name: "Czechia",
    flag: "🇨🇿",
    isoAlpha2Code: "CZ",
    dial_code: "+420",
    isoAlpha3Code: "CZE",
    country: {
      en: "Czech Republic",
      fr: "République tchèque",
    },
    nationality: {
      en: "Czech",
      fr: "Tchèque",
    },
  },
  {
    name: "Denmark",
    flag: "🇩🇰",
    isoAlpha2Code: "DK",
    dial_code: "+45",
    isoAlpha3Code: "DNK",
    country: {
      en: "Denmark",
      fr: "Danemark",
    },
    nationality: {
      en: "Danish",
      fr: "Danoise",
    },
  },
  {
    name: "Djibouti",
    flag: "🇩🇯",
    isoAlpha2Code: "DJ",
    dial_code: "+253",
    isoAlpha3Code: "DJI",
    country: {
      en: "Djibouti",
      fr: "Djibouti",
    },
    nationality: {
      en: "Djiboutian",
      fr: "Djiboutienne",
    },
  },
  {
    name: "Dominica",
    flag: "🇩🇲",
    isoAlpha2Code: "DM",
    dial_code: "+1767",
    isoAlpha3Code: "DMA",
    country: {
      en: "Dominica",
      fr: "Dominique",
    },
    nationality: {
      en: "Dominican",
      fr: "Dominiquaise",
    },
  },
  {
    name: "Dominican Republic",
    flag: "🇩🇴",
    isoAlpha2Code: "DO",
    dial_code: "+1809",
    isoAlpha3Code: "DOM",
    country: {
      en: "Dominican Republic",
      fr: "République dominicaine",
    },
    nationality: {
      en: "Dominican",
      fr: "Dominicaine",
    },
  },
  {
    name: "Ecuador",
    flag: "🇪🇨",
    isoAlpha2Code: "EC",
    dial_code: "+593",
    isoAlpha3Code: "ECU",
    country: {
      en: "Ecuador",
      fr: "Équateur",
    },
    nationality: {
      en: "Ecuadorian",
      fr: "Équatorienne",
    },
  },
  {
    name: "Egypt",
    flag: "🇪🇬",
    isoAlpha2Code: "EG",
    dial_code: "+20",
    isoAlpha3Code: "EGY",
    country: {
      en: "Egypt",
      fr: "Égypte",
    },
    nationality: {
      en: "Egyptian",
      fr: "Égyptienne",
    },
  },
  {
    name: "El Salvador",
    flag: "🇸🇻",
    isoAlpha2Code: "SV",
    dial_code: "+503",
    isoAlpha3Code: "SLV",
    country: {
      en: "El Salvador",
      fr: "Salvador",
    },
    nationality: {
      en: "Salvadoran",
      fr: "Salvadorienne",
    },
  },
  {
    name: "Equatorial Guinea",
    flag: "🇬🇶",
    isoAlpha2Code: "GQ",
    dial_code: "+240",
    isoAlpha3Code: "GNQ",
    country: {
      en: "Equatorial Guinea",
      fr: "Guinée équatoriale",
    },
    nationality: {
      en: "Equatorial Guinean",
      fr: "Equato-guinéenne",
    },
  },
  {
    name: "Eritrea",
    flag: "🇪🇷",
    isoAlpha2Code: "ER",
    dial_code: "+291",
    isoAlpha3Code: "ERI",
    country: {
      en: "Eritrea",
      fr: "Érythrée",
    },
    nationality: {
      en: "Eritrean",
      fr: "Érythréenne",
    },
  },
  {
    name: "Estonia",
    flag: "🇪🇪",
    isoAlpha2Code: "EE",
    dial_code: "+372",
    isoAlpha3Code: "EST",
    country: {
      en: "Estonia",
      fr: "Estonie",
    },
    nationality: {
      en: "Estonian",
      fr: "Estonienne",
    },
  },
  {
    name: "Eswatini",
    flag: "🇸🇿",
    isoAlpha2Code: "SZ",
    dial_code: "+268",
    isoAlpha3Code: "SWZ",
    country: {
      en: "Eswatini",
      fr: "Eswatini",
    },
    nationality: {
      en: "Swazi",
      fr: "Swazie",
    },
  },
  {
    name: "Ethiopia",
    flag: "🇪🇹",
    isoAlpha2Code: "ET",
    dial_code: "+251",
    isoAlpha3Code: "ETH",
    country: {
      en: "Ethiopia",
      fr: "Éthiopie",
    },
    nationality: {
      en: "Ethiopian",
      fr: "Éthiopienne",
    },
  },
  {
    name: "Falkland Islands (Malvinas)",
    flag: "🇫🇰",
    isoAlpha2Code: "FK",
    dial_code: "+500",
    isoAlpha3Code: "FLK",
    country: {
      en: "Falkland Islands (Malvinas)",
      fr: "Îles Falkland (Malvinas)",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Faroe Islands",
    flag: "🇫🇴",
    isoAlpha2Code: "FO",
    dial_code: "+298",
    isoAlpha3Code: "FRO",
    country: {
      en: "Faroe Islands",
      fr: "Îles Féroé",
    },
    nationality: {
      en: "Faroese",
      fr: "Féroïenne",
    },
  },
  {
    name: "Fiji",
    flag: "🇫🇯",
    isoAlpha2Code: "FJ",
    dial_code: "+679",
    isoAlpha3Code: "FJI",
    country: {
      en: "Fiji",
      fr: "Fidji",
    },
    nationality: {
      en: "Fijian",
      fr: "Fidjienne",
    },
  },
  {
    name: "Finland",
    flag: "🇫🇮",
    isoAlpha2Code: "FI",
    dial_code: "+358",
    isoAlpha3Code: "FIN",
    country: {
      en: "Finland",
      fr: "Finlande",
    },
    nationality: {
      en: "Finnish",
      fr: "Finlandaise",
    },
  },
  {
    name: "French Guiana",
    flag: "🇬🇫",
    isoAlpha2Code: "GF",
    dial_code: "+594",
    isoAlpha3Code: "GUF",
    country: {
      en: "French Guiana",
      fr: "Guyane française",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "French Polynesia",
    flag: "🇵🇫",
    isoAlpha2Code: "PF",
    dial_code: "+689",
    isoAlpha3Code: "PYF",
    country: {
      en: "French Polynesia",
      fr: "Polynésie française",
    },
    nationality: {
      en: "French Polynesian",
      fr: "Polynésienne",
    },
  },
  {
    name: "French Southern Territories",
    flag: "🇹🇫",
    isoAlpha2Code: "TF",
    dial_code: "+262",
    isoAlpha3Code: "ATF",
    country: {
      en: "French Southern Territories",
      fr: "Terres australes françaises",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Gabon",
    flag: "🇬🇦",
    isoAlpha2Code: "GA",
    dial_code: "+241",
    isoAlpha3Code: "GAB",
    country: {
      en: "Gabon",
      fr: "Gabon",
    },
    nationality: {
      en: "Gabonese",
      fr: "Gabonaise",
    },
  },
  {
    name: "Gambia",
    flag: "🇬🇲",
    isoAlpha2Code: "GM",
    dial_code: "+220",
    isoAlpha3Code: "GMB",
    country: {
      en: "Gambia",
      fr: "Gambie",
    },
    nationality: {
      en: "Gambian",
      fr: "Gambienne",
    },
  },
  {
    name: "Georgia",
    flag: "🇬🇪",
    isoAlpha2Code: "GE",
    dial_code: "+995",
    isoAlpha3Code: "GEO",
    country: {
      en: "Georgia",
      fr: "Géorgie",
    },
    nationality: {
      en: "Georgian",
      fr: "Géorgienne",
    },
  },
  {
    name: "Germany",
    flag: "🇩🇪",
    isoAlpha2Code: "DE",
    dial_code: "+49",
    isoAlpha3Code: "DEU",
    country: {
      en: "Germany",
      fr: "Allemagne",
    },
    nationality: {
      en: "German",
      fr: "Allemande",
    },
  },
  {
    name: "Ghana",
    flag: "🇬🇭",
    isoAlpha2Code: "GH",
    dial_code: "+233",
    isoAlpha3Code: "GHA",
    country: {
      en: "Ghana",
      fr: "Ghana",
    },
    nationality: {
      en: "Ghanaian",
      fr: "Ghanéenne",
    },
  },
  {
    name: "Gibraltar",
    flag: "🇬🇮",
    isoAlpha2Code: "GI",
    dial_code: "+350",
    isoAlpha3Code: "GIB",
    country: {
      en: "Gibraltar",
      fr: "Gibraltar",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Greece",
    flag: "🇬🇷",
    isoAlpha2Code: "GR",
    dial_code: "+30",
    isoAlpha3Code: "GRC",
    country: {
      en: "Greece",
      fr: "Grèce",
    },
    nationality: {
      en: "Greek",
      fr: "Grecque",
    },
  },
  {
    name: "Greenland",
    flag: "🇬🇱",
    isoAlpha2Code: "GL",
    dial_code: "+299",
    isoAlpha3Code: "GRL",
    country: {
      en: "Greenland",
      fr: "Groenland",
    },
    nationality: {
      en: "Greenlandic",
      fr: "Groenlandaise",
    },
  },
  {
    name: "Grenada",
    flag: "🇬🇩",
    isoAlpha2Code: "GD",
    dial_code: "+1473",
    isoAlpha3Code: "GRD",
    country: {
      en: "Grenada",
      fr: "Grenade",
    },
    nationality: {
      en: "Grenadian",
      fr: "Grenadienne",
    },
  },
  {
    name: "Guadeloupe",
    flag: "🇬🇵",
    isoAlpha2Code: "GP",
    dial_code: "+590",
    isoAlpha3Code: "GLP",
    country: {
      en: "Guadeloupe",
      fr: "Guadeloupe",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Guam",
    flag: "🇬🇺",
    isoAlpha2Code: "GU",
    dial_code: "+1671",
    isoAlpha3Code: "GUM",
    country: {
      en: "Guam",
      fr: "Guam",
    },
    nationality: {
      en: "Guamanian",
      fr: "Guamanienne",
    },
  },
  {
    name: "Guatemala",
    flag: "🇬🇹",
    isoAlpha2Code: "GT",
    dial_code: "+502",
    isoAlpha3Code: "GTM",
    country: {
      en: "Guatemala",
      fr: "Guatemala",
    },
    nationality: {
      en: "Guatemalan",
      fr: "Guatémaltèque",
    },
  },
  {
    name: "Guernsey",
    flag: "🇬🇬",
    isoAlpha2Code: "GG",
    dial_code: "+44",
    isoAlpha3Code: "GGY",
    country: {
      en: "Guernsey",
      fr: "Guernesey",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Guinea",
    flag: "🇬🇳",
    isoAlpha2Code: "GN",
    dial_code: "+224",
    isoAlpha3Code: "GIN",
    country: {
      en: "Guinea",
      fr: "Guinée",
    },
    nationality: {
      en: "Guinean",
      fr: "Guinéenne",
    },
  },
  {
    name: "Guinea-Bissau",
    flag: "🇬🇼",
    isoAlpha2Code: "GW",
    dial_code: "+245",
    isoAlpha3Code: "GNB",
    country: {
      en: "Guinea-Bissau",
      fr: "Guinée-Bissau",
    },
    nationality: {
      en: "Guinea-Bissauan",
      fr: "Bissau-Guinéenne",
    },
  },
  {
    name: "Guyana",
    flag: "🇬🇾",
    isoAlpha2Code: "GY",
    dial_code: "+592",
    isoAlpha3Code: "GUY",
    country: {
      en: "Guyana",
      fr: "Guyana",
    },
    nationality: {
      en: "Guyanese",
      fr: "Guyanienne",
    },
  },
  {
    name: "Haiti",
    flag: "🇭🇹",
    isoAlpha2Code: "HT",
    dial_code: "+509",
    isoAlpha3Code: "HTI",
    country: {
      en: "Haiti",
      fr: "Haïti",
    },
    nationality: {
      en: "Haitian",
      fr: "Haïtienne",
    },
  },
  {
    name: "Heard Island and McDonald Islands",
    flag: "🇭🇲",
    isoAlpha2Code: "HM",
    dial_code: "+672",
    isoAlpha3Code: "HMD",
    country: {
      en: "Heard Island and McDonald Islands",
      fr: "Îles Heard-et-MacDonald",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Holy See",
    flag: "🇻🇦",
    isoAlpha2Code: "VA",
    dial_code: "+39",
    isoAlpha3Code: "VAT",
    country: {
      en: "Holy See",
      fr: "Saint-Siège",
    },
    nationality: {
      en: "Vatican",
      fr: "Vaticane",
    },
  },
  {
    name: "Honduras",
    flag: "🇭🇳",
    isoAlpha2Code: "HN",
    dial_code: "+504",
    isoAlpha3Code: "HND",
    country: {
      en: "Honduras",
      fr: "Honduras",
    },
    nationality: {
      en: "Honduran",
      fr: "Hondurienne",
    },
  },
  {
    name: "Hong Kong",
    flag: "🇭🇰",
    isoAlpha2Code: "HK",
    dial_code: "+852",
    isoAlpha3Code: "HKG",
    country: {
      en: "Hong Kong",
      fr: "Hong Kong",
    },
    nationality: {
      en: "Hong Kong",
      fr: "Hongkongaise",
    },
  },
  {
    name: "Hungary",
    flag: "🇭🇺",
    isoAlpha2Code: "HU",
    dial_code: "+36",
    isoAlpha3Code: "HUN",
    country: {
      en: "Hungary",
      fr: "Hongrie",
    },
    nationality: {
      en: "Hungarian",
      fr: "Hongroise",
    },
  },
  {
    name: "Iceland",
    flag: "🇮🇸",
    isoAlpha2Code: "IS",
    dial_code: "+354",
    isoAlpha3Code: "ISL",
    country: {
      en: "Iceland",
      fr: "Islande",
    },
    nationality: {
      en: "Icelander",
      fr: "Islandaise",
    },
  },
  {
    name: "India",
    flag: "🇮🇳",
    isoAlpha2Code: "IN",
    dial_code: "+91",
    isoAlpha3Code: "IND",
    country: {
      en: "India",
      fr: "Inde",
    },
    nationality: {
      en: "Indian",
      fr: "Indienne",
    },
  },
  {
    name: "Indonesia",
    flag: "🇮🇩",
    isoAlpha2Code: "ID",
    dial_code: "+62",
    isoAlpha3Code: "IDN",
    country: {
      en: "Indonesia",
      fr: "Indonésie",
    },
    nationality: {
      en: "Indonesian",
      fr: "Indonésienne",
    },
  },
  {
    name: "Côte d'Ivoire",
    flag: "🇨🇮",
    isoAlpha2Code: "CI",
    dial_code: "+225",
    isoAlpha3Code: "CIV",
    country: {
      en: "Côte d'Ivoire",
      fr: "Côte d'Ivoire",
    },
    nationality: {
      en: "Ivorian",
      fr: "Ivoirienne",
    },
  },
  {
    name: "Iran (Islamic Republic of)",
    flag: "🇮🇷",
    isoAlpha2Code: "IR",
    dial_code: "+98",
    isoAlpha3Code: "IRN",
    country: {
      en: "Iran (Islamic Republic of)",
      fr: "Iran (République islamique d')",
    },
    nationality: {
      en: "Iranian",
      fr: "Iranienne",
    },
  },
  {
    name: "Iraq",
    flag: "🇮🇶",
    isoAlpha2Code: "IQ",
    dial_code: "+964",
    isoAlpha3Code: "IRQ",
    country: {
      en: "Iraq",
      fr: "Irak",
    },
    nationality: {
      en: "Iraqi",
      fr: "Irakienne",
    },
  },
  {
    name: "Ireland",
    flag: "🇮🇪",
    isoAlpha2Code: "IE",
    dial_code: "+353",
    isoAlpha3Code: "IRL",
    country: {
      en: "Ireland",
      fr: "Irlande",
    },
    nationality: {
      en: "Irish",
      fr: "Irlandaise",
    },
  },
  {
    name: "Isle of Man",
    flag: "🇮🇲",
    isoAlpha2Code: "IM",
    dial_code: "+44",
    isoAlpha3Code: "IMN",
    country: {
      en: "Isle of Man",
      fr: "Île de Man",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Israel",
    flag: "🇮🇱",
    isoAlpha2Code: "IL",
    dial_code: "+972",
    isoAlpha3Code: "ISR",
    country: {
      en: "Israel",
      fr: "Israël",
    },
    nationality: {
      en: "Israeli",
      fr: "Israélienne",
    },
  },
  {
    name: "Italy",
    flag: "🇮🇹",
    isoAlpha2Code: "IT",
    dial_code: "+39",
    isoAlpha3Code: "ITA",
    country: {
      en: "Italy",
      fr: "Italie",
    },
    nationality: {
      en: "Italian",
      fr: "Italienne",
    },
  },
  {
    name: "Jamaica",
    flag: "🇯🇲",
    isoAlpha2Code: "JM",
    dial_code: "+1876",
    isoAlpha3Code: "JAM",
    country: {
      en: "Jamaica",
      fr: "Jamaïque",
    },
    nationality: {
      en: "Jamaican",
      fr: "Jamaïcaine",
    },
  },
  {
    name: "Japan",
    flag: "🇯🇵",
    isoAlpha2Code: "JP",
    dial_code: "+81",
    isoAlpha3Code: "JPN",
    country: {
      en: "Japan",
      fr: "Japon",
    },
    nationality: {
      en: "Japanese",
      fr: "Japonaise",
    },
  },
  {
    name: "Jersey",
    flag: "🇯🇪",
    isoAlpha2Code: "JE",
    dial_code: "+44",
    isoAlpha3Code: "JEY",
    country: {
      en: "Jersey",
      fr: "Jersey",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Jordan",
    flag: "🇯🇴",
    isoAlpha2Code: "JO",
    dial_code: "+962",
    isoAlpha3Code: "JOR",
    country: {
      en: "Jordan",
      fr: "Jordanie",
    },
    nationality: {
      en: "Jordanian",
      fr: "Jordanienne",
    },
  },
  {
    name: "Kazakhstan",
    flag: "🇰🇿",
    isoAlpha2Code: "KZ",
    dial_code: "+7",
    isoAlpha3Code: "KAZ",
    country: {
      en: "Kazakhstan",
      fr: "Kazakhstan",
    },
    nationality: {
      en: "Kazakhstani",
      fr: "Kazakhe",
    },
  },
  {
    name: "Kenya",
    flag: "🇰🇪",
    isoAlpha2Code: "KE",
    dial_code: "+254",
    isoAlpha3Code: "KEN",
    country: {
      en: "Kenya",
      fr: "Kenya",
    },
    nationality: {
      en: "Kenyan",
      fr: "Kenyane",
    },
  },
  {
    name: "Kiribati",
    flag: "🇰🇮",
    isoAlpha2Code: "KI",
    dial_code: "+686",
    isoAlpha3Code: "KIR",
    country: {
      en: "Kiribati",
      fr: "Kiribati",
    },
    nationality: {
      en: "I-Kiribati",
      fr: "Kiribatienne",
    },
  },
  {
    name: "Korea (Democratic People's Republic of)",
    flag: "🇰🇵",
    isoAlpha2Code: "KP",
    dial_code: "+850",
    isoAlpha3Code: "PRK",
    country: {
      en: "Korea (Democratic People's Republic of)",
      fr: "Corée (République populaire démocratique de)",
    },
    nationality: {
      en: "North Korean",
      fr: "Nord-Coréenne",
    },
  },
  {
    name: "Korea (Republic of)",
    flag: "🇰🇷",
    isoAlpha2Code: "KR",
    dial_code: "+82",
    isoAlpha3Code: "KOR",
    country: {
      en: "Korea (Republic of)",
      fr: "Corée (République de)",
    },
    nationality: {
      en: "South Korean",
      fr: "Sud-Coréenne",
    },
  },
  {
    name: "Kuwait",
    flag: "🇰🇼",
    isoAlpha2Code: "KW",
    dial_code: "+965",
    isoAlpha3Code: "KWT",
    country: {
      en: "Kuwait",
      fr: "Koweït",
    },
    nationality: {
      en: "Kuwaiti",
      fr: "Koweïtienne",
    },
  },
  {
    name: "Kyrgyzstan",
    flag: "🇰🇬",
    isoAlpha2Code: "KG",
    dial_code: "+996",
    isoAlpha3Code: "KGZ",
    country: {
      en: "Kyrgyzstan",
      fr: "Kirghizistan",
    },
    nationality: {
      en: "Kyrgyzstani",
      fr: "Kirghize",
    },
  },
  {
    name: "Lao People's Democratic Republic",
    flag: "🇱🇦",
    isoAlpha2Code: "LA",
    dial_code: "+856",
    isoAlpha3Code: "LAO",
    country: {
      en: "Lao People's Democratic Republic",
      fr: "Laos (République démocratique populaire)",
    },
    nationality: {
      en: "Laotian",
      fr: "Laotienne",
    },
  },
  {
    name: "Latvia",
    flag: "🇱🇻",
    isoAlpha2Code: "LV",
    dial_code: "+371",
    isoAlpha3Code: "LVA",
    country: {
      en: "Latvia",
      fr: "Lettonie",
    },
    nationality: {
      en: "Latvian",
      fr: "Lettone",
    },
  },
  {
    name: "Lebanon",
    flag: "🇱🇧",
    isoAlpha2Code: "LB",
    dial_code: "+961",
    isoAlpha3Code: "LBN",
    country: {
      en: "Lebanon",
      fr: "Liban",
    },
    nationality: {
      en: "Lebanese",
      fr: "Libanaise",
    },
  },
  {
    name: "Lesotho",
    flag: "🇱🇸",
    isoAlpha2Code: "LS",
    dial_code: "+266",
    isoAlpha3Code: "LSO",
    country: {
      en: "Lesotho",
      fr: "Lesotho",
    },
    nationality: {
      en: "Basotho",
      fr: "Lesothane",
    },
  },
  {
    name: "Liberia",
    flag: "🇱🇷",
    isoAlpha2Code: "LR",
    dial_code: "+231",
    isoAlpha3Code: "LBR",
    country: {
      en: "Liberia",
      fr: "Libéria",
    },
    nationality: {
      en: "Liberian",
      fr: "Libérienne",
    },
  },
  {
    name: "Libya",
    flag: "🇱🇾",
    isoAlpha2Code: "LY",
    dial_code: "+218",
    isoAlpha3Code: "LBY",
    country: {
      en: "Libya",
      fr: "Libye",
    },
    nationality: {
      en: "Libyan",
      fr: "Libyenne",
    },
  },
  {
    name: "Liechtenstein",
    flag: "🇱🇮",
    isoAlpha2Code: "LI",
    dial_code: "+423",
    isoAlpha3Code: "LIE",
    country: {
      en: "Liechtenstein",
      fr: "Liechtenstein",
    },
    nationality: {
      en: "Liechtensteiner",
      fr: "Liechtensteinoise",
    },
  },
  {
    name: "Lithuania",
    flag: "🇱🇹",
    isoAlpha2Code: "LT",
    dial_code: "+370",
    isoAlpha3Code: "LTU",
    country: {
      en: "Lithuania",
      fr: "Lituanie",
    },
    nationality: {
      en: "Lithuanian",
      fr: "Lituanienne",
    },
  },
  {
    name: "Luxembourg",
    flag: "🇱🇺",
    isoAlpha2Code: "LU",
    dial_code: "+352",
    isoAlpha3Code: "LUX",
    country: {
      en: "Luxembourg",
      fr: "Luxembourg",
    },
    nationality: {
      en: "Luxembourger",
      fr: "Luxembourgeoise",
    },
  },
  {
    name: "Macao",
    flag: "🇲🇴",
    isoAlpha2Code: "MO",
    dial_code: "+853",
    isoAlpha3Code: "MAC",
    country: {
      en: "Macao",
      fr: "Macao",
    },
    nationality: {
      en: "Macanese",
      fr: "Macao",
    },
  },
  {
    name: "Madagascar",
    flag: "🇲🇬",
    isoAlpha2Code: "MG",
    dial_code: "+261",
    isoAlpha3Code: "MDG",
    country: {
      en: "Madagascar",
      fr: "Madagascar",
    },
    nationality: {
      en: "Malagasy",
      fr: "Malgache",
    },
  },
  {
    name: "Malawi",
    flag: "🇲🇼",
    isoAlpha2Code: "MW",
    dial_code: "+265",
    isoAlpha3Code: "MWI",
    country: {
      en: "Malawi",
      fr: "Malawi",
    },
    nationality: {
      en: "Malawian",
      fr: "Malawienne",
    },
  },
  {
    name: "Malaysia",
    flag: "🇲🇾",
    isoAlpha2Code: "MY",
    dial_code: "+60",
    isoAlpha3Code: "MYS",
    country: {
      en: "Malaysia",
      fr: "Malaisie",
    },
    nationality: {
      en: "Malaysian",
      fr: "Malaisienne",
    },
  },
  {
    name: "Maldives",
    flag: "🇲🇻",
    isoAlpha2Code: "MV",
    dial_code: "+960",
    isoAlpha3Code: "MDV",
    country: {
      en: "Maldives",
      fr: "Maldives",
    },
    nationality: {
      en: "Maldivian",
      fr: "Maldivienne",
    },
  },
  {
    name: "Mali",
    flag: "🇲🇱",
    isoAlpha2Code: "ML",
    dial_code: "+223",
    isoAlpha3Code: "MLI",
    country: {
      en: "Mali",
      fr: "Mali",
    },
    nationality: {
      en: "Malian",
      fr: "Malienne",
    },
  },
  {
    name: "Malta",
    flag: "🇲🇹",
    isoAlpha2Code: "MT",
    dial_code: "+356",
    isoAlpha3Code: "MLT",
    country: {
      en: "Malta",
      fr: "Malte",
    },
    nationality: {
      en: "Maltese",
      fr: "Maltaise",
    },
  },
  {
    name: "Marshall Islands",
    flag: "🇲🇭",
    isoAlpha2Code: "MH",
    dial_code: "+692",
    isoAlpha3Code: "MHL",
    country: {
      en: "Marshall Islands",
      fr: "Îles Marshall",
    },
    nationality: {
      en: "Marshallese",
      fr: "Marshallaise",
    },
  },
  {
    name: "Martinique",
    flag: "🇲🇶",
    isoAlpha2Code: "MQ",
    dial_code: "+596",
    isoAlpha3Code: "MTQ",
    country: {
      en: "Martinique",
      fr: "Martinique",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Mauritania",
    flag: "🇲🇷",
    isoAlpha2Code: "MR",
    dial_code: "+222",
    isoAlpha3Code: "MRT",
    country: {
      en: "Mauritania",
      fr: "Mauritanie",
    },
    nationality: {
      en: "Mauritanian",
      fr: "Mauritanienne",
    },
  },
  {
    name: "Mauritius",
    flag: "🇲🇺",
    isoAlpha2Code: "MU",
    dial_code: "+230",
    isoAlpha3Code: "MUS",
    country: {
      en: "Mauritius",
      fr: "Maurice",
    },
    nationality: {
      en: "Mauritian",
      fr: "Mauricienne",
    },
  },
  {
    name: "Mayotte",
    flag: "🇾🇹",
    isoAlpha2Code: "YT",
    dial_code: "+262",
    isoAlpha3Code: "MYT",
    country: {
      en: "Mayotte",
      fr: "Mayotte",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Mexico",
    flag: "🇲🇽",
    isoAlpha2Code: "MX",
    dial_code: "+52",
    isoAlpha3Code: "MEX",
    country: {
      en: "Mexico",
      fr: "Mexique",
    },
    nationality: {
      en: "Mexican",
      fr: "Mexicaine",
    },
  },
  {
    name: "Micronesia (Federated States of)",
    flag: "🇫🇲",
    isoAlpha2Code: "FM",
    dial_code: "+691",
    isoAlpha3Code: "FSM",
    country: {
      en: "Micronesia (Federated States of)",
      fr: "Micronésie (États fédérés de)",
    },
    nationality: {
      en: "Micronesian",
      fr: "Micronésienne",
    },
  },
  {
    name: "Moldova (Republic of)",
    flag: "🇲🇩",
    isoAlpha2Code: "MD",
    dial_code: "+373",
    isoAlpha3Code: "MDA",
    country: {
      en: "Moldova (Republic of)",
      fr: "Moldavie (République de)",
    },
    nationality: {
      en: "Moldovan",
      fr: "Moldave",
    },
  },
  {
    name: "Monaco",
    flag: "🇲🇨",
    isoAlpha2Code: "MC",
    dial_code: "+377",
    isoAlpha3Code: "MCO",
    country: {
      en: "Monaco",
      fr: "Monaco",
    },
    nationality: {
      en: "Monégasque",
      fr: "Monégasque",
    },
  },
  {
    name: "Mongolia",
    flag: "🇲🇳",
    isoAlpha2Code: "MN",
    dial_code: "+976",
    isoAlpha3Code: "MNG",
    country: {
      en: "Mongolia",
      fr: "Mongolie",
    },
    nationality: {
      en: "Mongolian",
      fr: "Mongole",
    },
  },
  {
    name: "Montenegro",
    flag: "🇲🇪",
    isoAlpha2Code: "ME",
    dial_code: "+382",
    isoAlpha3Code: "MNE",
    country: {
      en: "Montenegro",
      fr: "Monténégro",
    },
    nationality: {
      en: "Montenegrin",
      fr: "Monténégrine",
    },
  },
  {
    name: "Montserrat",
    flag: "🇲🇸",
    isoAlpha2Code: "MS",
    dial_code: "+1664",
    isoAlpha3Code: "MSR",
    country: {
      en: "Montserrat",
      fr: "Montserrat",
    },
    nationality: {
      en: "Montserratian",
      fr: "Montserratienne",
    },
  },
  {
    name: "Morocco",
    flag: "🇲🇦",
    isoAlpha2Code: "MA",
    dial_code: "+212",
    isoAlpha3Code: "MAR",
    country: {
      en: "Morocco",
      fr: "Maroc",
    },
    nationality: {
      en: "Moroccan",
      fr: "Marocaine",
    },
  },
  {
    name: "Mozambique",
    flag: "🇲🇿",
    isoAlpha2Code: "MZ",
    dial_code: "+258",
    isoAlpha3Code: "MOZ",
    country: {
      en: "Mozambique",
      fr: "Mozambique",
    },
    nationality: {
      en: "Mozambican",
      fr: "Mozambicaine",
    },
  },
  {
    name: "Myanmar",
    flag: "🇲🇲",
    isoAlpha2Code: "MM",
    dial_code: "+95",
    isoAlpha3Code: "MMR",
    country: {
      en: "Myanmar",
      fr: "Myanmar",
    },
    nationality: {
      en: "Burmese",
      fr: "Birmane",
    },
  },
  {
    name: "Namibia",
    flag: "🇳🇦",
    isoAlpha2Code: "NA",
    dial_code: "+264",
    isoAlpha3Code: "NAM",
    country: {
      en: "Namibia",
      fr: "Namibie",
    },
    nationality: {
      en: "Namibian",
      fr: "Namibienne",
    },
  },
  {
    name: "Nauru",
    flag: "🇳🇷",
    isoAlpha2Code: "NR",
    dial_code: "+674",
    isoAlpha3Code: "NRU",
    country: {
      en: "Nauru",
      fr: "Nauru",
    },
    nationality: {
      en: "Nauruan",
      fr: "Nauruane",
    },
  },
  {
    name: "Nepal",
    flag: "🇳🇵",
    isoAlpha2Code: "NP",
    dial_code: "+977",
    isoAlpha3Code: "NPL",
    country: {
      en: "Nepal",
      fr: "Népal",
    },
    nationality: {
      en: "Nepali",
      fr: "Népalaise",
    },
  },
  {
    name: "Netherlands",
    flag: "🇳🇱",
    isoAlpha2Code: "NL",
    dial_code: "+31",
    isoAlpha3Code: "NLD",
    country: {
      en: "Netherlands",
      fr: "Pays-Bas",
    },
    nationality: {
      en: "Dutch",
      fr: "Néerlandaise",
    },
  },
  {
    name: "New Caledonia",
    flag: "🇳🇨",
    isoAlpha2Code: "NC",
    dial_code: "+687",
    isoAlpha3Code: "NCL",
    country: {
      en: "New Caledonia",
      fr: "Nouvelle-Calédonie",
    },
    nationality: {
      en: "New Caledonian",
      fr: "Néo-Calédonienne",
    },
  },
  {
    name: "New Zealand",
    flag: "🇳🇿",
    isoAlpha2Code: "NZ",
    dial_code: "+64",
    isoAlpha3Code: "NZL",
    country: {
      en: "New Zealand",
      fr: "Nouvelle-Zélande",
    },
    nationality: {
      en: "New Zealander",
      fr: "Néo-Zélandaise",
    },
  },
  {
    name: "Nicaragua",
    flag: "🇳🇮",
    isoAlpha2Code: "NI",
    dial_code: "+505",
    isoAlpha3Code: "NIC",
    country: {
      en: "Nicaragua",
      fr: "Nicaragua",
    },
    nationality: {
      en: "Nicaraguan",
      fr: "Nicaraguayenne",
    },
  },
  {
    name: "Niger",
    flag: "🇳🇪",
    isoAlpha2Code: "NE",
    dial_code: "+227",
    isoAlpha3Code: "NER",
    country: {
      en: "Niger",
      fr: "Niger",
    },
    nationality: {
      en: "Nigerien",
      fr: "Nigérienne",
    },
  },
  {
    name: "Nigeria",
    flag: "🇳🇬",
    isoAlpha2Code: "NG",
    dial_code: "+234",
    isoAlpha3Code: "NGA",
    country: {
      en: "Nigeria",
      fr: "Nigéria",
    },
    nationality: {
      en: "Nigerian",
      fr: "Nigériane",
    },
  },
  {
    name: "Niue",
    flag: "🇳🇺",
    isoAlpha2Code: "NU",
    dial_code: "+683",
    isoAlpha3Code: "NIU",
    country: {
      en: "Niue",
      fr: "Niue",
    },
    nationality: {
      en: "Niuean",
      fr: "Niuéenne",
    },
  },
  {
    name: "Norfolk Island",
    flag: "🇳🇫",
    isoAlpha2Code: "NF",
    dial_code: "+672",
    isoAlpha3Code: "NFK",
    country: {
      en: "Norfolk Island",
      fr: "Île Norfolk",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "North Macedonia",
    flag: "🇲🇰",
    isoAlpha2Code: "MK",
    dial_code: "+389",
    isoAlpha3Code: "MKD",
    country: {
      en: "North Macedonia",
      fr: "Macédoine du Nord",
    },
    nationality: {
      en: "Macedonian",
      fr: "Macédonienne",
    },
  },
  {
    name: "Northern Mariana Islands",
    flag: "🇲🇵",
    isoAlpha2Code: "MP",
    dial_code: "+1670",
    isoAlpha3Code: "MNP",
    country: {
      en: "Northern Mariana Islands",
      fr: "Îles Mariannes du Nord",
    },
    nationality: {
      en: "Northern Marianan",
      fr: "Mariannais du Nord",
    },
  },
  {
    name: "Norway",
    flag: "🇳🇴",
    isoAlpha2Code: "NO",
    dial_code: "+47",
    isoAlpha3Code: "NOR",
    country: {
      en: "Norway",
      fr: "Norvège",
    },
    nationality: {
      en: "Norwegian",
      fr: "Norvégienne",
    },
  },
  {
    name: "Oman",
    flag: "🇴🇲",
    isoAlpha2Code: "OM",
    dial_code: "+968",
    isoAlpha3Code: "OMN",
    country: {
      en: "Oman",
      fr: "Oman",
    },
    nationality: {
      en: "Omani",
      fr: "Omanaise",
    },
  },
  {
    name: "Pakistan",
    flag: "🇵🇰",
    isoAlpha2Code: "PK",
    dial_code: "+92",
    isoAlpha3Code: "PAK",
    country: {
      en: "Pakistan",
      fr: "Pakistan",
    },
    nationality: {
      en: "Pakistani",
      fr: "Pakistanais",
    },
  },
  {
    name: "Palau",
    flag: "🇵🇼",
    isoAlpha2Code: "PW",
    dial_code: "+680",
    isoAlpha3Code: "PLW",
    country: {
      en: "Palau",
      fr: "Palaos",
    },
    nationality: {
      en: "Palauan",
      fr: "Paluane",
    },
  },
  {
    name: "Palestine, State of",
    flag: "🇵🇸",
    isoAlpha2Code: "PS",
    dial_code: "+970",
    isoAlpha3Code: "PSE",
    country: {
      en: "Palestine, State of",
      fr: "Palestine, État de",
    },
    nationality: {
      en: "Palestinian",
      fr: "Palestinienne",
    },
  },
  {
    name: "Panama",
    flag: "🇵🇦",
    isoAlpha2Code: "PA",
    dial_code: "+507",
    isoAlpha3Code: "PAN",
    country: {
      en: "Panama",
      fr: "Panama",
    },
    nationality: {
      en: "Panamanian",
      fr: "Panaméenne",
    },
  },
  {
    name: "Papua New Guinea",
    flag: "🇵🇬",
    isoAlpha2Code: "PG",
    dial_code: "+675",
    isoAlpha3Code: "PNG",
    country: {
      en: "Papua New Guinea",
      fr: "Papouasie-Nouvelle-Guinée",
    },
    nationality: {
      en: "Papua New Guinean",
      fr: "Papouasienne-Néo-Guinéenne",
    },
  },
  {
    name: "Paraguay",
    flag: "🇵🇾",
    isoAlpha2Code: "PY",
    dial_code: "+595",
    isoAlpha3Code: "PRY",
    country: {
      en: "Paraguay",
      fr: "Paraguay",
    },
    nationality: {
      en: "Paraguayan",
      fr: "Paraguayenne",
    },
  },
  {
    name: "Peru",
    flag: "🇵🇪",
    isoAlpha2Code: "PE",
    dial_code: "+51",
    isoAlpha3Code: "PER",
    country: {
      en: "Peru",
      fr: "Pérou",
    },
    nationality: {
      en: "Peruvian",
      fr: "Péruvienne",
    },
  },
  {
    name: "Philippines",
    flag: "🇵🇭",
    isoAlpha2Code: "PH",
    dial_code: "+63",
    isoAlpha3Code: "PHL",
    country: {
      en: "Philippines",
      fr: "Philippines",
    },
    nationality: {
      en: "Filipino",
      fr: "Philippine",
    },
  },
  {
    name: "Pitcairn",
    flag: "🇵🇳",
    isoAlpha2Code: "PN",
    dial_code: "+64",
    isoAlpha3Code: "PCN",
    country: {
      en: "Pitcairn",
      fr: "Îles Pitcairn",
    },
    nationality: {
      en: "Pitcairn Islander",
      fr: "Pitcairnaise",
    },
  },
  {
    name: "Poland",
    flag: "🇵🇱",
    isoAlpha2Code: "PL",
    dial_code: "+48",
    isoAlpha3Code: "POL",
    country: {
      en: "Poland",
      fr: "Pologne",
    },
    nationality: {
      en: "Polish",
      fr: "Polonaise",
    },
  },
  {
    name: "Portugal",
    flag: "🇵🇹",
    isoAlpha2Code: "PT",
    dial_code: "+351",
    isoAlpha3Code: "PRT",
    country: {
      en: "Portugal",
      fr: "Portugal",
    },
    nationality: {
      en: "Portuguese",
      fr: "Portugaise",
    },
  },
  {
    name: "Puerto Rico",
    flag: "🇵🇷",
    isoAlpha2Code: "PR",
    dial_code: "+1",
    isoAlpha3Code: "PRI",
    country: {
      en: "Puerto Rico",
      fr: "Porto Rico",
    },
    nationality: {
      en: "Puerto Rican",
      fr: "Portoricaine",
    },
  },
  {
    name: "Qatar",
    flag: "🇶🇦",
    isoAlpha2Code: "QA",
    dial_code: "+974",
    isoAlpha3Code: "QAT",
    country: {
      en: "Qatar",
      fr: "Qatar",
    },
    nationality: {
      en: "Qatari",
      fr: "Qatarienne",
    },
  },
  {
    name: "Réunion",
    flag: "🇷🇪",
    isoAlpha2Code: "RE",
    dial_code: "+262",
    isoAlpha3Code: "REU",
    country: {
      en: "Réunion",
      fr: "Réunion",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Romania",
    flag: "🇷🇴",
    isoAlpha2Code: "RO",
    dial_code: "+40",
    isoAlpha3Code: "ROU",
    country: {
      en: "Romania",
      fr: "Roumanie",
    },
    nationality: {
      en: "Romanian",
      fr: "Roumaine",
    },
  },
  {
    name: "Russian Federation",
    flag: "🇷🇺",
    isoAlpha2Code: "RU",
    dial_code: "+7",
    isoAlpha3Code: "RUS",
    country: {
      en: "Russian Federation",
      fr: "Russie",
    },
    nationality: {
      en: "Russian",
      fr: "Russe",
    },
  },
  {
    name: "Rwanda",
    flag: "🇷🇼",
    isoAlpha2Code: "RW",
    dial_code: "+250",
    isoAlpha3Code: "RWA",
    country: {
      en: "Rwanda",
      fr: "Rwanda",
    },
    nationality: {
      en: "Rwandan",
      fr: "Rwandaise",
    },
  },
  {
    name: "Saint Barthélemy",
    flag: "🇧🇱",
    isoAlpha2Code: "BL",
    dial_code: "+590",
    isoAlpha3Code: "BLM",
    country: {
      en: "Saint Barthélemy",
      fr: "Saint-Barthélémy",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    flag: "🇸🇭",
    isoAlpha2Code: "SH",
    dial_code: "+290",
    isoAlpha3Code: "SHN",
    country: {
      en: "Saint Helena, Ascension and Tristan da Cunha",
      fr: "Sainte-Hélène, Ascension et Tristan da Cunha",
    },
    nationality: {
      en: "Saint Helenian",
      fr: "Sainte-Hélène",
    },
  },
  {
    name: "Saint Kitts and Nevis",
    flag: "🇰🇳",
    isoAlpha2Code: "KN",
    dial_code: "+1869",
    isoAlpha3Code: "KNA",
    country: {
      en: "Saint Kitts and Nevis",
      fr: "Saint-Christophe-et-Niévès",
    },
    nationality: {
      en: "Kittitian or Nevisian",
      fr: "Kittitienne ou Névicienne",
    },
  },
  {
    name: "Saint Lucia",
    flag: "🇱🇨",
    isoAlpha2Code: "LC",
    dial_code: "+1758",
    isoAlpha3Code: "LCA",
    country: {
      en: "Saint Lucia",
      fr: "Sainte-Lucie",
    },
    nationality: {
      en: "Saint Lucian",
      fr: "Saint-Lucienne",
    },
  },
  {
    name: "Saint Martin (French part)",
    flag: "🇲🇫",
    isoAlpha2Code: "MF",
    dial_code: "+590",
    isoAlpha3Code: "MAF",
    country: {
      en: "Saint Martin (French part)",
      fr: "Saint-Martin (partie française)",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Saint Pierre and Miquelon",
    flag: "🇵🇲",
    isoAlpha2Code: "PM",
    dial_code: "+508",
    isoAlpha3Code: "SPM",
    country: {
      en: "Saint Pierre and Miquelon",
      fr: "Saint-Pierre-et-Miquelon",
    },
    nationality: {
      en: "Saint-Pierrais or Miquelonnais",
      fr: "Saint-Pierraise ou Miquelonaise",
    },
  },
  {
    name: "Saint Vincent and the Grenadines",
    flag: "🇻🇨",
    isoAlpha2Code: "VC",
    dial_code: "+1784",
    isoAlpha3Code: "VCT",
    country: {
      en: "Saint Vincent and the Grenadines",
      fr: "Saint-Vincent-et-les Grenadines",
    },
    nationality: {
      en: "Saint Vincentian",
      fr: "Saint-Vincentaise",
    },
  },
  {
    name: "Samoa",
    flag: "🇼🇸",
    isoAlpha2Code: "WS",
    dial_code: "+685",
    isoAlpha3Code: "WSM",
    country: {
      en: "Samoa",
      fr: "Samoa",
    },
    nationality: {
      en: "Samoan",
      fr: "Samoane",
    },
  },
  {
    name: "San Marino",
    flag: "🇸🇲",
    isoAlpha2Code: "SM",
    dial_code: "+378",
    isoAlpha3Code: "SMR",
    country: {
      en: "San Marino",
      fr: "Saint-Marin",
    },
    nationality: {
      en: "San Marinese",
      fr: "Saint-Marinaise",
    },
  },
  {
    name: "Sao Tome and Principe",
    flag: "🇸🇹",
    isoAlpha2Code: "ST",
    dial_code: "+239",
    isoAlpha3Code: "STP",
    country: {
      en: "Sao Tome and Principe",
      fr: "Sao Tomé-et-Principe",
    },
    nationality: {
      en: "Sao Tomean",
      fr: "Santoméenne",
    },
  },
  {
    name: "Saudi Arabia",
    flag: "🇸🇦",
    isoAlpha2Code: "SA",
    dial_code: "+966",
    isoAlpha3Code: "SAU",
    country: {
      en: "Saudi Arabia",
      fr: "Arabie saoudite",
    },
    nationality: {
      en: "Saudi Arabian",
      fr: "Saoudienne",
    },
  },
  {
    name: "Senegal",
    flag: "🇸🇳",
    isoAlpha2Code: "SN",
    dial_code: "+221",
    isoAlpha3Code: "SEN",
    country: {
      en: "Senegal",
      fr: "Sénégal",
    },
    nationality: {
      en: "Senegalese",
      fr: "Sénégalaise",
    },
  },
  {
    name: "Serbia",
    flag: "🇷🇸",
    isoAlpha2Code: "RS",
    dial_code: "+381",
    isoAlpha3Code: "SRB",
    country: {
      en: "Serbia",
      fr: "Serbie",
    },
    nationality: {
      en: "Serbian",
      fr: "Serbe",
    },
  },
  {
    name: "Seychelles",
    flag: "🇸🇨",
    isoAlpha2Code: "SC",
    dial_code: "+248",
    isoAlpha3Code: "SYC",
    country: {
      en: "Seychelles",
      fr: "Seychelles",
    },
    nationality: {
      en: "Seychellois",
      fr: "Seychelloise",
    },
  },
  {
    name: "Sierra Leone",
    flag: "🇸🇱",
    isoAlpha2Code: "SL",
    dial_code: "+232",
    isoAlpha3Code: "SLE",
    country: {
      en: "Sierra Leone",
      fr: "Sierra Leone",
    },
    nationality: {
      en: "Sierra Leonean",
      fr: "Sierra-Léonaise",
    },
  },
  {
    name: "Singapore",
    flag: "🇸🇬",
    isoAlpha2Code: "SG",
    dial_code: "+65",
    isoAlpha3Code: "SGP",
    country: {
      en: "Singapore",
      fr: "Singapour",
    },
    nationality: {
      en: "Singaporean",
      fr: "Singapourienne",
    },
  },
  {
    name: "Sint Maarten (Dutch part)",
    flag: "🇸🇽",
    isoAlpha2Code: "SX",
    dial_code: "+1721",
    isoAlpha3Code: "SXM",
    country: {
      en: "Sint Maarten (Dutch part)",
      fr: "Saint-Martin (partie néerlandaise)",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Slovakia",
    flag: "🇸🇰",
    isoAlpha2Code: "SK",
    dial_code: "+421",
    isoAlpha3Code: "SVK",
    country: {
      en: "Slovakia",
      fr: "Slovaquie",
    },
    nationality: {
      en: "Slovak",
      fr: "Slovaque",
    },
  },
  {
    name: "Slovenia",
    flag: "🇸🇮",
    isoAlpha2Code: "SI",
    dial_code: "+386",
    isoAlpha3Code: "SVN",
    country: {
      en: "Slovenia",
      fr: "Slovénie",
    },
    nationality: {
      en: "Slovenian",
      fr: "Slovène",
    },
  },
  {
    name: "Solomon Islands",
    flag: "🇸🇧",
    isoAlpha2Code: "SB",
    dial_code: "+677",
    isoAlpha3Code: "SLB",
    country: {
      en: "Solomon Islands",
      fr: "Îles Salomon",
    },
    nationality: {
      en: "Solomon Islander",
      fr: "Salomonaise",
    },
  },
  {
    name: "Somalia",
    flag: "🇸🇴",
    isoAlpha2Code: "SO",
    dial_code: "+252",
    isoAlpha3Code: "SOM",
    country: {
      en: "Somalia",
      fr: "Somalie",
    },
    nationality: {
      en: "Somali",
      fr: "Somalienne",
    },
  },
  {
    name: "South Africa",
    flag: "🇿🇦",
    isoAlpha2Code: "ZA",
    dial_code: "+27",
    isoAlpha3Code: "ZAF",
    country: {
      en: "South Africa",
      fr: "Afrique du Sud",
    },
    nationality: {
      en: "South African",
      fr: "Sud-Africaine",
    },
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    flag: "🇬🇸",
    isoAlpha2Code: "GS",
    dial_code: "+500",
    isoAlpha3Code: "SGS",
    country: {
      en: "South Georgia and the South Sandwich Islands",
      fr: "Géorgie du Sud-et-les Îles Sandwich du Sud",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "South Sudan",
    flag: "🇸🇸",
    isoAlpha2Code: "SS",
    dial_code: "+211",
    isoAlpha3Code: "SSD",
    country: {
      en: "South Sudan",
      fr: "Soudan du Sud",
    },
    nationality: {
      en: "South Sudanese",
      fr: "Soudanaise du Sud",
    },
  },
  {
    name: "Spain",
    flag: "🇪🇸",
    isoAlpha2Code: "ES",
    dial_code: "+34",
    isoAlpha3Code: "ESP",
    country: {
      en: "Spain",
      fr: "Espagne",
    },
    nationality: {
      en: "Spanish",
      fr: "Espagnole",
    },
  },
  {
    name: "Sri Lanka",
    flag: "🇱🇰",
    isoAlpha2Code: "LK",
    dial_code: "+94",
    isoAlpha3Code: "LKA",
    country: {
      en: "Sri Lanka",
      fr: "Sri Lanka",
    },
    nationality: {
      en: "Sri Lankan",
      fr: "Sri-Lankaise",
    },
  },
  {
    name: "Sudan",
    flag: "🇸🇩",
    isoAlpha2Code: "SD",
    dial_code: "+249",
    isoAlpha3Code: "SDN",
    country: {
      en: "Sudan",
      fr: "Soudan",
    },
    nationality: {
      en: "Sudanese",
      fr: "Soudanaise",
    },
  },
  {
    name: "Suriname",
    flag: "🇸🇷",
    isoAlpha2Code: "SR",
    dial_code: "+597",
    isoAlpha3Code: "SUR",
    country: {
      en: "Suriname",
      fr: "Suriname",
    },
    nationality: {
      en: "Surinamer",
      fr: "Surinamaise",
    },
  },
  {
    name: "Svalbard and Jan Mayen",
    flag: "🇸🇯",
    isoAlpha2Code: "SJ",
    dial_code: "+47",
    isoAlpha3Code: "SJM",
    country: {
      en: "Svalbard and Jan Mayen",
      fr: "Svalbard et Jan Mayen",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Sweden",
    flag: "🇸🇪",
    isoAlpha2Code: "SE",
    dial_code: "+46",
    isoAlpha3Code: "SWE",
    country: {
      en: "Sweden",
      fr: "Suède",
    },
    nationality: {
      en: "Swedish",
      fr: "Suédoise",
    },
  },
  {
    name: "Switzerland",
    flag: "🇨🇭",
    isoAlpha2Code: "CH",
    dial_code: "+41",
    isoAlpha3Code: "CHE",
    country: {
      en: "Switzerland",
      fr: "Suisse",
    },
    nationality: {
      en: "Swiss",
      fr: "Suisse",
    },
  },
  {
    name: "Syrian Arab Republic",
    flag: "🇸🇾",
    isoAlpha2Code: "SY",
    dial_code: "+963",
    isoAlpha3Code: "SYR",
    country: {
      en: "Syrian Arab Republic",
      fr: "République arabe syrienne",
    },
    nationality: {
      en: "Syrian",
      fr: "Syrienne",
    },
  },
  {
    name: "Taiwan",
    flag: "🇹🇼",
    isoAlpha2Code: "TW",
    dial_code: "+886",
    isoAlpha3Code: "TWN",
    country: {
      en: "Taiwan",
      fr: "Taïwan",
    },
    nationality: {
      en: "Taiwanese",
      fr: "Taïwanaise",
    },
  },
  {
    name: "Tajikistan",
    flag: "🇹🇯",
    isoAlpha2Code: "TJ",
    dial_code: "+992",
    isoAlpha3Code: "TJK",
    country: {
      en: "Tajikistan",
      fr: "Tadjikistan",
    },
    nationality: {
      en: "Tajikistani",
      fr: "Tadjike",
    },
  },
  {
    name: "Tanzania, United Republic of",
    flag: "🇹🇿",
    isoAlpha2Code: "TZ",
    dial_code: "+255",
    isoAlpha3Code: "TZA",
    country: {
      en: "Tanzania, United Republic of",
      fr: "Tanzanie, République-Unie de",
    },
    nationality: {
      en: "Tanzanian",
      fr: "Tanzanienne",
    },
  },
  {
    name: "Thailand",
    flag: "🇹🇭",
    isoAlpha2Code: "TH",
    dial_code: "+66",
    isoAlpha3Code: "THA",
    country: {
      en: "Thailand",
      fr: "Thaïlande",
    },
    nationality: {
      en: "Thai",
      fr: "Thaïlandaise",
    },
  },
  {
    name: "Timor-Leste",
    flag: "🇹🇱",
    isoAlpha2Code: "TL",
    dial_code: "+670",
    isoAlpha3Code: "TLS",
    country: {
      en: "Timor-Leste",
      fr: "Timor-Leste",
    },
    nationality: {
      en: "East Timorese",
      fr: "Est-Timoraise",
    },
  },
  {
    name: "Togo",
    flag: "🇹🇬",
    isoAlpha2Code: "TG",
    dial_code: "+228",
    isoAlpha3Code: "TGO",
    country: {
      en: "Togo",
      fr: "Togo",
    },
    nationality: {
      en: "Togolese",
      fr: "Togolaise",
    },
  },
  {
    name: "Tokelau",
    flag: "🇹🇰",
    isoAlpha2Code: "TK",
    dial_code: "+690",
    isoAlpha3Code: "TKL",
    country: {
      en: "Tokelau",
      fr: "Tokelau",
    },
    nationality: {
      en: "Tokelauan",
      fr: "Tokelauane",
    },
  },
  {
    name: "Tonga",
    flag: "🇹🇴",
    isoAlpha2Code: "TO",
    dial_code: "+676",
    isoAlpha3Code: "TON",
    country: {
      en: "Tonga",
      fr: "Tonga",
    },
    nationality: {
      en: "Tongan",
      fr: "Tongienne",
    },
  },
  {
    name: "Trinidad and Tobago",
    flag: "🇹🇹",
    isoAlpha2Code: "TT",
    dial_code: "+1868",
    isoAlpha3Code: "TTO",
    country: {
      en: "Trinidad and Tobago",
      fr: "Trinité-et-Tobago",
    },
    nationality: {
      en: "Trinidadian or Tobagonian",
      fr: "Trinidadienne ou Tobagonienne",
    },
  },
  {
    name: "Tunisia",
    flag: "🇹🇳",
    isoAlpha2Code: "TN",
    dial_code: "+216",
    isoAlpha3Code: "TUN",
    country: {
      en: "Tunisia",
      fr: "Tunisie",
    },
    nationality: {
      en: "Tunisian",
      fr: "Tunisienne",
    },
  },
  {
    name: "Turkey",
    flag: "🇹🇷",
    isoAlpha2Code: "TR",
    dial_code: "+90",
    isoAlpha3Code: "TUR",
    country: {
      en: "Turkey",
      fr: "Turquie",
    },
    nationality: {
      en: "Turkish",
      fr: "Turque",
    },
  },
  {
    name: "Turkmenistan",
    flag: "🇹🇲",
    isoAlpha2Code: "TM",
    dial_code: "+993",
    isoAlpha3Code: "TKM",
    country: {
      en: "Turkmenistan",
      fr: "Turkménistan",
    },
    nationality: {
      en: "Turkmen",
      fr: "Turkmène",
    },
  },
  {
    name: "Turks and Caicos Islands",
    flag: "🇹🇨",
    isoAlpha2Code: "TC",
    dial_code: "+1649",
    isoAlpha3Code: "TCA",
    country: {
      en: "Turks and Caicos Islands",
      fr: "Îles Turques-et-Caïques",
    },
    nationality: {
      en: "Turks and Caicos Islander",
      fr: "Turques-et-Caïquaise",
    },
  },
  {
    name: "Tuvalu",
    flag: "🇹🇻",
    isoAlpha2Code: "TV",
    dial_code: "+688",
    isoAlpha3Code: "TUV",
    country: {
      en: "Tuvalu",
      fr: "Tuvalu",
    },
    nationality: {
      en: "Tuvaluan",
      fr: "Tuvaluane",
    },
  },
  {
    name: "Uganda",
    flag: "🇺🇬",
    isoAlpha2Code: "UG",
    dial_code: "+256",
    isoAlpha3Code: "UGA",
    country: {
      en: "Uganda",
      fr: "Ouganda",
    },
    nationality: {
      en: "Ugandan",
      fr: "Ougandaise",
    },
  },
  {
    name: "United States Minor Outlying Islands",
    flag: "🇺🇲",
    isoAlpha2Code: "UM",
    dial_code: "+1",
    isoAlpha3Code: "UMI",
    country: {
      en: "United States Minor Outlying Islands",
      fr: "Îles mineures éloignées des États-Unis",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Ukraine",
    flag: "🇺🇦",
    isoAlpha2Code: "UA",
    dial_code: "+380",
    isoAlpha3Code: "UKR",
    country: {
      en: "Ukraine",
      fr: "Ukraine",
    },
    nationality: {
      en: "Ukrainian",
      fr: "Ukrainienne",
    },
  },
  {
    name: "United Arab Emirates",
    flag: "🇦🇪",
    isoAlpha2Code: "AE",
    dial_code: "+971",
    isoAlpha3Code: "ARE",
    country: {
      en: "United Arab Emirates",
      fr: "Emirats arabes unis",
    },
    nationality: {
      en: "Emirati",
      fr: "Emirienne",
    },
  },
  {
    name: "Uruguay",
    flag: "🇺🇾",
    isoAlpha2Code: "UY",
    dial_code: "+598",
    isoAlpha3Code: "URY",
    country: {
      en: "Uruguay",
      fr: "Uruguay",
    },
    nationality: {
      en: "Uruguayan",
      fr: "Uruguayenne",
    },
  },
  {
    name: "Uzbekistan",
    flag: "🇺🇿",
    isoAlpha2Code: "UZ",
    dial_code: "+998",
    isoAlpha3Code: "UZB",
    country: {
      en: "Uzbekistan",
      fr: "Ouzbékistan",
    },
    nationality: {
      en: "Uzbekistani",
      fr: "Ouzbèke",
    },
  },
  {
    name: "Vanuatu",
    flag: "🇻🇺",
    isoAlpha2Code: "VU",
    dial_code: "+678",
    isoAlpha3Code: "VUT",
    country: {
      en: "Vanuatu",
      fr: "Vanuatu",
    },
    nationality: {
      en: "Ni-Vanuatu",
      fr: "Néo-Hébridaise",
    },
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    flag: "🇻🇪",
    isoAlpha2Code: "VE",
    dial_code: "+58",
    isoAlpha3Code: "VEN",
    country: {
      en: "Venezuela (Bolivarian Republic of)",
      fr: "Venezuela (République bolivarienne du)",
    },
    nationality: {
      en: "Venezuelan",
      fr: "Vénézuélienne",
    },
  },
  {
    name: "Viet Nam",
    flag: "🇻🇳",
    isoAlpha2Code: "VN",
    dial_code: "+84",
    isoAlpha3Code: "VNM",
    country: {
      en: "Viet Nam",
      fr: "Viet Nam",
    },
    nationality: {
      en: "Vietnamese",
      fr: "Vietnamienne",
    },
  },
  {
    name: "Virgin Islands (British)",
    flag: "🇻🇬",
    isoAlpha2Code: "VG",
    dial_code: "+1284",
    isoAlpha3Code: "VGB",
    country: {
      en: "Virgin Islands (British)",
      fr: "Îles Vierges britanniques",
    },
    nationality: {
      en: "British Virgin Islander",
      fr: "Britannique",
    },
  },
  {
    name: "Virgin Islands (U.S.)",
    flag: "🇻🇮",
    isoAlpha2Code: "VI",
    dial_code: "+1340",
    isoAlpha3Code: "VIR",
    country: {
      en: "Virgin Islands (U.S.)",
      fr: "Îles Vierges américaines",
    },
    nationality: {
      en: "U.S. Virgin Islander",
      fr: "Américaine",
    },
  },
  {
    name: "Wallis and Futuna",
    flag: "🇼🇫",
    isoAlpha2Code: "WF",
    dial_code: "+681",
    isoAlpha3Code: "WLF",
    country: {
      en: "Wallis and Futuna",
      fr: "Wallis-et-Futuna",
    },
    nationality: {
      en: "Wallis and Futuna Islander",
      fr: "Wallisienne-et-Futunienne",
    },
  },
  {
    name: "Western Sahara",
    flag: "🇪🇭",
    isoAlpha2Code: "EH",
    dial_code: "+212",
    isoAlpha3Code: "ESH",
    country: {
      en: "Western Sahara",
      fr: "Sahara occidental",
    },
    nationality: {
      en: null,
      fr: null,
    },
  },
  {
    name: "Yemen",
    flag: "🇾🇪",
    isoAlpha2Code: "YE",
    dial_code: "+967",
    isoAlpha3Code: "YEM",
    country: {
      en: "Yemen",
      fr: "Yémen",
    },
    nationality: {
      en: "Yemeni",
      fr: "Yéménite",
    },
  },
  {
    name: "Zambia",
    flag: "🇿🇲",
    isoAlpha2Code: "ZM",
    dial_code: "+260",
    isoAlpha3Code: "ZMB",
    country: {
      en: "Zambia",
      fr: "Zambie",
    },
    nationality: {
      en: "Zambian",
      fr: "Zambienne",
    },
  },
  {
    name: "Zimbabwe",
    flag: "🇿🇼",
    isoAlpha2Code: "ZW",
    dial_code: "+263",
    isoAlpha3Code: "ZWE",
    country: {
      en: "Zimbabwe",
      fr: "Zimbabwe",
    },
    nationality: {
      en: "Zimbabwean",
      fr: "Zimbabwéenne",
    },
  },
];
