import { countries } from "./assets/countries";

export const getCountryCodeByPhone = (phone: string | null) => {
  const countryCode = countries.find((country) => {
    return phone?.startsWith(country.dial_code);
  });

  return countryCode?.isoAlpha2Code || countries[0]?.isoAlpha2Code;
};

export const findCountryByCode = (code: string) => {
  return (
    countries.find(
      (country) =>
        country.isoAlpha2Code === code || country.isoAlpha3Code === code
    ) ?? null
  );
};

export const getNationalityByCode = (code: string, locale: "fr" | "en") =>
  findCountryByCode(code)?.nationality[locale] ?? null;

export const findCountryByName = (name: string) => {
  return countries.find(
    (country) => country.country.en === name || country.country.fr === name
  );
};
